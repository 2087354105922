import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {PageScrollService} from 'ngx-page-scroll-core';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';

@Component({
	selector: 'app-terms',
	templateUrl: './terms.component.html',
	styleUrls: ['./terms.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsComponent implements OnInit, AfterViewInit {

	public loaded = false;

	constructor(private titleService: Title,
				private changeDetector: ChangeDetectorRef,
				private pageScrollService: PageScrollService,
				@Inject(DOCUMENT) private document: any) {
	}

	ngOnInit() {
		this.titleService.setTitle('Terms of use');
	}

	ngAfterViewInit() {
		this.loaded = true;
		this.changeDetector.detectChanges();
		this.pageScrollService.scroll({
			document: this.document,
			scrollTarget: '.internal-content',
		});
	}
}
