import {GeoTag} from './../models/GeoTag';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Collection} from '../models/Collection';
import {APIService} from '../services/api.service';
import {ActivatedRoute} from '@angular/router';
import {ResourceType} from '../models/ResourceType';
import {Resource} from '../models/Resource';
import {Tag} from '../models/Tag';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {environment} from '../../environments/environment';
import {Title} from '@angular/platform-browser';

@Component({
	selector: 'app-collection',
	templateUrl: './collection.component.html',
	styleUrls: ['./collection.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionComponent implements OnInit {

	public collection: Collection = new Collection();
	public expanded_types: string[] = [];

	constructor(
		private titleService: Title,
		private apiService: APIService,
		private route: ActivatedRoute,
		private ngxSmartModalService: NgxSmartModalService,
		private changeDetector: ChangeDetectorRef
	) {
	}

	// Resource display logic

	public openModal(resource: Resource): void {
		this.ngxSmartModalService.setModalData(resource, 'resourceModal');
		this.ngxSmartModalService.getModal('resourceModal').open();
	}

	public clearModelData() {
		this.ngxSmartModalService.resetModalData('resourceModal');
	}

	public openGraphModal(resource: Resource): void {
		this.ngxSmartModalService.setModalData(resource, 'resourceChartModal');
		this.ngxSmartModalService.getModal('resourceChartModal').open();
	}

	public clearGraphModelData() {
		this.ngxSmartModalService.resetModalData('resourceChartModal');
	}

	public toggleExpanded(type: string) {
		if (this.expanded_types.includes(type)) {
			this.expanded_types.splice(this.expanded_types.indexOf(type), 1);
		} else {
			this.expanded_types.push(type);
		}
		return false;
	}

	public getDisplayedArr(type: string, arr: Resource[] | GeoTag[] | Tag[]): Resource[] | GeoTag[] | Tag[] {
		return (this.expanded_types.includes(type)) ? arr : arr.slice(0, 4);
	}

	public countTotalResults(): Number {
		let totalCount = 0;
		this.apiService.getResourceTypes(this.result_resources).forEach((type: ResourceType) => {
			totalCount += this.apiService.filterResourcesByType(type.title, this.result_resources).length;
		});

		return totalCount;
	}

	public filterResultsByType(type: string): Resource[] {
		return this.apiService.filterResourcesByType(type, this.result_resources);
	}

	public getResourceTypes(): ResourceType[] {
		return this.apiService.getResourceTypes(this.result_resources).map(type => new ResourceType(type));
	}

	public viewerJSUrlCast(url: string): string {
		return url.replace(environment.api, environment.api + '/ViewerJS/index.html#');
	}

	public viewerMicrosfotCast(url: string): string {
		return 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url);
	}

	get result_resources(): Resource[] {
		return this.collection.resources;
	}

	// End resource display logic

	ngOnInit() {
		this.apiService.getCollection(this.route.snapshot.params.collection_id).subscribe((response: Collection) => {
			this.collection = response;
			this.titleService.setTitle(response.title + ' collection');
			this.changeDetector.detectChanges();
		});
	}

}
