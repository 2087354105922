import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {APIService} from '../services/api.service';
import {Tag} from '../models/Tag';
import {Collection} from '../models/Collection';
import {Title} from '@angular/platform-browser';

@Component({
	selector: 'app-splash',
	templateUrl: './splash.component.html',
	styleUrls: ['./splash.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SplashComponent implements OnInit {

	public tag: Tag = new Tag();
	public collections: Collection[] = [];

	constructor(
		private apiService: APIService,
		private titleService: Title
	) {
	}

	ngOnInit() {
		this.titleService.setTitle('Welcome');

		// this.apiService.getTagsBasic().subscribe((tags: Tag[]) => {
		//     this.tag = this.apiService.getTagByTerm('projects', 'projects', tags);
		//     this.apiService.getTagCollections(this.tag.id).subscribe((collections: Collection[]) => {
		//         this.collections = collections;
		//     });
		// });
	}

}
