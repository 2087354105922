import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import {User} from '../models/User';
import {environment} from '../../environments/environment';
import has = Reflect.has;

@Injectable({providedIn: 'root'})
export class AuthenticationService {
	private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);

	constructor(
		private http: HttpClient
	) {}

	public get currentUser(): Observable<User> {
		if (this.currentUserSubject.observers.length < 1) {
			const tokenObjStr = localStorage.getItem('currentUser');
			if (tokenObjStr) {
				const jwtObj = JSON.parse(tokenObjStr);
				if (has(jwtObj, 'token')) {
					const userObj = (jwt_decode(jwtObj['token']) as User);
					if (Number(((new Date()).getTime() / 1000).toFixed(0)) < userObj['exp']) {
						userObj.token = jwtObj['token'];
						this.currentUserSubject.next((userObj as User));
					} else {
						this.logout();
					}
				} else {
					this.logout();
				}
			}
		}

		return this.currentUserSubject.asObservable();
	}

	login(username: string, password: string) {
		return this.http.post<any>(environment.api + '/api/getToken', {'username': username, 'password': password})
			.pipe(map(jwtObj => {
				// store jwtObj details and jwt token in local storage to keep jwtObj logged in between page refreshes
				localStorage.setItem('currentUser', JSON.stringify(jwtObj));
				if (has(jwtObj, 'token')) {
					const userObj = (jwt_decode(jwtObj['token']) as User);
					userObj.token = jwtObj['token'];
					this.currentUserSubject.next((userObj as User));
				} else {
					throwError('Invalid JWT');
				}
			}));
	}

	logout() {
		// remove user from local storage to log user out
		localStorage.removeItem('currentUser');
		this.currentUserSubject.next(undefined);
	}
}
