import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private authenticationService: AuthenticationService
	) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.authenticationService.currentUser.pipe(
			map(user => {
				if (user) {
					return true;
				} else {
					this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
					return false;
				}
			}), catchError((err) => {
				console.log(err);
				this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
				return of(false);
			})
		);
	}
}
