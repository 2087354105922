import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
	selector: 'app-info',
	templateUrl: './info.component.html',
	styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

	constructor(private titleService: Title) {
	}

	ngOnInit() {
		this.titleService.setTitle('Our Story');
	}

}
