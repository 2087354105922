import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	public siteCopyright: string;
	public currentYear: string;

	constructor() {
	}

	ngOnInit() {
		this.siteCopyright = environment.copyright;
		this.currentYear = String((new Date()).getFullYear());
	}

}
