import {APIService} from '../services/api.service';
import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {dTree} from '../renderers/dtree';
import {Task} from '../models/Task';
import {Title} from '@angular/platform-browser';

@Component({
	selector: 'app-geneology',
	templateUrl: './geneology.component.html',
	styleUrls: ['./geneology.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class GeneologyComponent implements AfterViewInit, OnInit {

	@ViewChild('graphHandle')
	graphHandle: ElementRef;
	@ViewChild('graphCont')
	graphCont: ElementRef;
	currentTask: any = undefined;
	currentView = 'map';
	model: any;

	constructor(
		// private activeTask: ActiveTask,
		// private activeView: ActiveView,
		public apiService: APIService,
		public titleService: Title
	) {

	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.render();
	}

	ngOnInit() {

		this.titleService.setTitle('Geneology');
		// this.activeView.watchView().subscribe((view) => {
		//     this.currentView = view;
		//     this.render();
		//     console.log('change');
		// });
		// this.activeTask.watchTask().subscribe((task) => {
		//     this.currentTask = task;
		//     this.render();
		// });

		this.apiService.getGeneology().subscribe((model) => {
			this.model = model;
			this.render();
		});
	}

	addTask(nodeId?: number) {
		const task = new Task();
		task.name = 'new task';
		task.class = 'man';
		task.extra = {
			id: 13
		};
		task.textClass = 'emphasis';
		this.model.model.push(task);
		this.render();
	}

	removeTask(nodeId?: number) {
		this.model.model.splice(0, 1);
		this.render();
	}

	editTask(nodeId?: number) {
		this.model.model[0].name = 'this is an edited name';
		this.render();
	}

	clickActive(name, extra) {
		// this.activeTask.setTask(extra);
		event.preventDefault();
		event.stopPropagation();
		return false;
	}

	render() {
		const that = this;
		const container = this.graphHandle.nativeElement;

		container.innerHTML = '';

		dTree.init(this.model,
			{
				target: container,
				debug: false,
				height: this.graphCont.nativeElement.clientHeight,
				width: this.graphCont.nativeElement.clientWidth,
				callbacks: {
					nodeClick: function (name, extra) {
						console.log(name, extra);
						// that.activeTask.setTask(extra);
					},
					textRenderer: function (name, extra, textClass) {
						if (extra && extra.nickname) {
							name = name + ` (` + extra.nickname + `)`;
						}
						return `<p align='center' class='` + textClass + `'>` + name + `</p>`;
					}
				}
			});
	}

	ngAfterViewInit() {

		this.render();

	}

	public removeMovedItem(item: any, list: any[]): void {
		list.splice(list.indexOf(item), 1);
		this.render();
	}

	addCategory(list) {
		list.push({
			'spouse': {
				'name': 'Task attribute x',
				'class': 'woman',
				'extra': {}
			},
			'children': []
		});
		this.render();
	}

	addCategoryTask(list) {
		list.push({
			'extra': {
				'id': 9,
			},
			'name': 'Task x',
			'class': 'man',
			'marriages': []
		});
		this.render();
	}
}
