import {Collection} from '../models/Collection';
import {APIService} from '../services/api.service';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Tag} from '../models/Tag';
import {Title} from '@angular/platform-browser';

@Component({
	selector: 'app-expo',
	templateUrl: './expo.component.html',
	styleUrls: ['./expo.component.scss']
})
export class ExpoComponent implements OnInit {
	public tag: Tag = new Tag();
	public collections: Collection[] = [];

	constructor(
		private titleService: Title,
		private apiService: APIService
	) {
	}

	ngOnInit() {
		console.log('this is ng on init');
		this.titleService.setTitle('Collections');
		// this.apiService.getTagsBasic().subscribe((tags: Tag[]) => {
		// const tmp_tag = this.apiService.getTagByTerm(params.tag_title, params.tag_title, tags);
		// if (tmp_tag.id) {
		this.apiService.getTag(116).subscribe(tag => {
			this.tag = tag;
			console.log('this is being run once');
			this.apiService.getTagCollections(this.tag.id).subscribe((collections: Collection[]) => {
				console.log('this is happening inside the subscription');
				this.collections = collections.sort((a, b) => {
					const collection1 = Number(a.title.toUpperCase().split('.')[0]);
					const collection2 = Number(b.title.toUpperCase().split('.')[0]);
					return (collection1 < collection2) ? -1 : (collection1 > collection2) ? 1 : 0;
				});
			});
		});
	}

}
