import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'encodeURI'
})
export class EncodeURI implements PipeTransform {

	public transform(url: any) {
		if (url === undefined) {
			return '';
		}

		return encodeURI(String(url));
	}
}
