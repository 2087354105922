import {BehaviorSubject} from 'rxjs';
import {Observable} from 'rxjs/Observable';

import {Injectable} from '@angular/core';

@Injectable()
export class LayerService {

	private mapLayerArray = Array<string>();
	private mapLayersSource = new BehaviorSubject<Array<string>>(this.mapLayerArray);

	constructor() {

	}

	mapLayersChanges(): Observable<any> {
		return this.mapLayersSource.asObservable();
	}

	clearMapLayers(): void {
		this.mapLayerArray = [];
		this.mapLayersSource.next(this.mapLayerArray);
	}

	setMapLayers(newSearchTerms: Array<string>): void {
		this.mapLayerArray = newSearchTerms;
		this.mapLayersSource.next(this.mapLayerArray);
	}

	addMapLayer(newSearchTerm: string): void {
		this.mapLayerArray.push(newSearchTerm);
		this.mapLayersSource.next(this.mapLayerArray);
	}

}
