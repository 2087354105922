import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
	selector: 'app-acknowledgements',
	templateUrl: './acknowledgements.component.html',
	styleUrls: ['./acknowledgements.component.scss']
})
export class AcknowledgementsComponent implements OnInit, AfterViewInit {

	public loaded = false;

	constructor(private titleService: Title) {
	}

	ngOnInit() {
		this.titleService.setTitle('Acknowledgements');
	}

	ngAfterViewInit() {
		this.loaded = true;
	}
}
