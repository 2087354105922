import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
	selector: 'app-help',
	templateUrl: './help.component.html',
	styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

	constructor(private titleService: Title) {
	}

	ngOnInit() {
		this.titleService.setTitle('Help');
	}

}
