import {Component, HostListener, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../../services/authentication.service';
import {User} from '../../models/User';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	public siteName: string;
	isSticky: true | false = false;
	user: User;

	constructor(private authenticationService: AuthenticationService) {
	}

	ngOnInit() {
		this.siteName = environment.title;
		this.authenticationService.currentUser.subscribe(user => {
			this.user = user;
		});
	}

	@HostListener('window:scroll', ['$event'])
	checkScroll() {
		this.isSticky = window.pageYOffset >= 100;
	}

}
