import * as _ from 'lodash';
import * as d3 from 'd3';

class TreeBuilder {
	static DEBUG_LEVEL: number;
	root: any;
	siblings: any;
	opts: any;
	allNodes: any[];
	nodeSize: any;
	svg: any;
	tree: any;

	static _nodeSize(nodes, width, textRenderer) {
		const maxWidth = 0;
		let maxHeight = 0;
		const tmpSvg = document.createElement('svg');
		document.body.appendChild(tmpSvg);

		_.map(nodes, function (n) {
			const container = document.createElement('div');
			container.setAttribute('class', n.data.class);
			container.style.visibility = 'hidden';
			container.style.maxWidth = width + 'px';

			const text = textRenderer(n.data.name, n.data.extra, n.data.textClass);
			container.innerHTML = text;

			tmpSvg.appendChild(container);
			const height = container.offsetHeight;
			tmpSvg.removeChild(container);

			maxHeight = Math.max(maxHeight, height);
			n.cHeight = height;
			if (n.data.hidden) {
				n.cWidth = 0;
			} else {
				n.cWidth = width;
			}
		});
		document.body.removeChild(tmpSvg);

		return [width, maxHeight];
	}

	static _nodeRenderer(name, x, y, height, width, extra, id, nodeClass, textClass, textRenderer) {
		let node = '';
		node += '<div ';
		node += 'style="height:100%;width:100%;padding:0px 2px;" ';
		node += 'class="' + nodeClass + '" ';
		node += 'id="node' + id + '">\n';
		node += textRenderer(name, extra, textClass);
		node += '</div>';
		return node;
	}

	static _textRenderer(name, extra, textClass) {
		let node = '';
		node += '<p ';
		node += 'align="center" ';
		node += 'class="' + textClass + '">\n';
		node += name;
		node += '</p>\n';
		return node;
	}

	static _debug(msg) {
		if (TreeBuilder.DEBUG_LEVEL > 0) {
			console.log(msg);
		}
	}

	constructor(root, siblings, opts) {
		TreeBuilder.DEBUG_LEVEL = opts.debug ? 1 : 0;

		this.root = root;
		this.siblings = siblings;
		this.opts = opts;

		// flatten nodes
		this.allNodes = this._flatten(this.root);

		// Calculate node size
		const visibleNodes = _.filter(this.allNodes, function (n) {
			return !n.hidden;
		});
		this.nodeSize = opts.callbacks.nodeSize(visibleNodes,
			opts.nodeWidth, opts.callbacks.textRenderer);
	}

	create() {

		const opts = this.opts;
		const allNodes = this.allNodes;
		const nodeSize = this.nodeSize;

		const width = opts.width + opts.margin.left + opts.margin.right;
		const height = opts.height + opts.margin.top + opts.margin.bottom;

		const zoom = d3.zoom()
			.scaleExtent([0.1, 10])
			.on('zoom', function () {
				svg.attr('transform', d3.event.transform.translate(width / 2, opts.margin.top));
			});

		// make an SVG
		const svg = this.svg = d3.select(opts.target)
			.append('svg')
			.attr('width', width)
			.attr('height', height)
			.call(zoom)
			.append('g')
			.attr('transform', 'translate(' + width / 2 + ',' + opts.margin.top + ')');

		// Compute the layout.
		this.tree = d3.tree()
			.nodeSize([nodeSize[0] * 2, nodeSize[1] * 2.5]);

		this.tree.separation(function separation(a, b) {
			if (a.data.hidden || b.data.hidden) {
				return 0.3;
			} else {
				return 0.6;
			}
		});

		this._update(this.root);

	}

	_update(source) {

		const opts = this.opts;
		const allNodes = this.allNodes;
		const nodeSize = this.nodeSize;

		const treenodes = this.tree(source);
		const links = treenodes.links();

		// Create the link lines.
		this.svg.selectAll('.link')
			.data(links)
			.enter()
			// filter links with no parents to prevent empty nodes
			.filter(function (l) {
				return !l.target.data.noParent;
			})
			.append('path')
			.attr('class', opts.styles.linage)
			.attr('d', this._elbow);

		const nodes = this.svg.selectAll('.node')
			.data(treenodes.descendants())
			.enter();

		this._linkSiblings();

		// Draw siblings (marriage)
		this.svg.selectAll('.sibling')
			.data(this.siblings)
			.enter()
			.append('path')
			.attr('class', opts.styles.marriage)
			.attr('d', _.bind(this._siblingLine, this));

		// Create the node rectangles.
		nodes.append('foreignObject')
			.filter(function (d) {
				return d.data.hidden ? false : true;
			})
			.attr('x', function (d) {
				return d.x - d.cWidth / 2 + 'px';
			})
			.attr('y', function (d) {
				return d.y - d.cHeight / 2 + 'px';
			})
			.attr('width', function (d) {
				return d.cWidth + 'px';
			})
			.attr('height', function (d) {
				return d.cHeight + 'px';
			})
			.attr('id', function (d) {
				return d.id;
			})
			.html(function (d) {
				return opts.callbacks.nodeRenderer(
					d.data.name,
					d.x,
					d.y,
					nodeSize[0],
					nodeSize[1],
					d.data.extra,
					d.data.id,
					d.data.class,
					d.data.textClass,
					opts.callbacks.textRenderer);
			})
			.on('click', function (d) {
				if (d.data.hidden) {
					return;
				}
				opts.callbacks.nodeClick(d.data.name, d.data.extra, d.data.id);
			});
	}

	_flatten(root) {
		const n = [];
		let i = 0;

		function recurse(node) {
			if (node.children) {
				node.children.forEach(recurse);
			}
			if (!node.id) {
				node.id = ++i;
			}
			n.push(node);
		}

		recurse(root);
		return n;
	}

	_elbow(d, i) {
		if (d.target.data.noParent) {
			return 'M0,0L0,0';
		}
		const ny = d.target.y + (d.source.y - d.target.y) * 0.50;

		const linedata = [{
			x: d.target.x,
			y: d.target.y
		}, {
			x: d.target.x,
			y: ny
		}, {
			x: d.source.x,
			y: d.source.y
		}];

		const fun: any = d3.line().curve(d3.curveStepAfter)
			.x((tmp_d: any) => tmp_d.x)
			.y((tmp_d: any) => tmp_d.y);
		return fun(linedata);
	}

	_linkSiblings() {

		const allNodes = this.allNodes;

		_.forEach(this.siblings, function (d) {
			const start = allNodes.filter(function (v) {
				return d.source.id === v.data.id;
			});
			const end = allNodes.filter(function (v) {
				return d.target.id === v.data.id;
			});
			d.source.x = start[0].x;
			d.source.y = start[0].y;
			d.target.x = end[0].x;
			d.target.y = end[0].y;

			const marriageId = (start[0].data.marriageNode != null ?
				start[0].data.marriageNode.id :
				end[0].data.marriageNode.id);
			const marriageNode = allNodes.find(function (n) {
				return n.data.id === marriageId;
			});
			d.source.marriageNode = marriageNode;
			d.target.marriageNode = marriageNode;
		});

	}

	_siblingLine(d, i) {

		let ny = d.target.y + (d.source.y - d.target.y) * 0.50;
		const nodeWidth = this.nodeSize[0];
		const nodeHeight = this.nodeSize[1];

		// Not first marriage
		if (d.number > 0) {
			ny -= nodeHeight * 8 / 10;
		}

		const linedata = [{
			x: d.source.x,
			y: d.source.y
		}, {
			x: d.source.x + nodeWidth * 6 / 10,
			y: d.source.y
		}, {
			x: d.source.x + nodeWidth * 6 / 10,
			y: ny
		}, {
			x: d.target.marriageNode.x,
			y: ny
		}, {
			x: d.target.marriageNode.x,
			y: d.target.y
		}, {
			x: d.target.x,
			y: d.target.y
		}];

		const fun: any = d3.line().curve(d3.curveStepAfter)
			.x((tmp_d: any) => tmp_d.x)
			.y((tmp_d: any) => tmp_d.y);
		return fun(linedata);
	}

}

export default TreeBuilder;
