import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AuthenticationService} from '../services/authentication.service';
import {Title} from '@angular/platform-browser';
import {User} from '../models/User';


@Component({templateUrl: 'login.component.html'})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	loading = false;
	submitted = false;
	returnUrl: string;
	error = '';
	user: User;

	constructor(
		private formBuilderService: FormBuilder,
		private routeService: ActivatedRoute,
		private routerService: Router,
		private authenticationService: AuthenticationService,
		private titleService: Title
	) {
	}

	ngOnInit() {
		this.titleService.setTitle('Sign In');

		this.loginForm = this.formBuilderService.group({
			username: ['', Validators.required],
			password: ['', Validators.required]
		});

		// get return url from route parameters or default to '/'
		this.returnUrl = this.routeService.snapshot.queryParams['returnUrl'] || '/';

		this.authenticationService.currentUser.subscribe(user => {
			this.user = user;
		});
	}

	// convenience getter for easy access to form fields
	get f() {
		return this.loginForm.controls;
	}

	onSubmit(): void {
		this.submitted = true;

		// stop here if form is invalid
		if (this.loginForm.invalid) {
			return;
		}

		this.loading = true;
		this.authenticationService.login(this.f.username.value, this.f.password.value)
			.subscribe(
				data => {
					this.routerService.navigate([this.returnUrl]);
				},
				error => {
					this.error = error;
					this.loading = false;
				});
	}

	signOut(): void {
		this.authenticationService.logout();
	}
}
