import {Collection} from './Collection';
import {Resource} from './Resource';

export class Tag {
	parent?: any;
	resource_count: number;
	tag_count: number;
	context: string;
	collection_count: number;
	children: Collection[] = [];
	resources: Resource[] = [];
	children_count: number;
	id: number;
	title = 'Loading';
	description = 'Please wait...';
	created: Date;
	modified: Date;
	thumbnail_url: string;
}
