import {LoginComponent} from './../login/login.component';
import {AcknowledgementsComponent} from './../acknowledgements/acknowledgements.component';
import {TermsComponent} from './../terms/terms.component';
import {ContactComponent} from './../contact/contact.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SearchComponent} from '../search/search.component';
import {ResultsComponent} from '../results/results.component';
import {InfoComponent} from '../info/info.component';
import {MapComponent} from '../map/map.component';
import {ExpoComponent} from '../expo/expo.component';
import {CollectionComponent} from '../collection/collection.component';
import {HelpComponent} from '../help/help.component';
import {GeneologyComponent} from '../geneology/geneology.component';
import {ResourceComponent} from '../resource/resource.component';
import {AuthGuard} from '../services/auth.guard';

const routes: Routes = [
	{
		path: 'collection/:collection_id',
		component: CollectionComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'expo/:tag_title',
		component: ExpoComponent,
		canActivate: [AuthGuard]
	},
	// {
	// 	path: 'about',
	// 	component: InfoComponent,
	// 	canActivate: [AuthGuard]
	// },
	{
		path: 'results/:tag_ids',
		component: ResultsComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'map',
		component: MapComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'map/geo_tags/:geo_tags',
		component: MapComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'map/map_layers/:map_layers',
		component: MapComponent,
		canActivate: [AuthGuard]
	},
	// {
	// 	path: 'info',
	// 	component: InfoComponent,
	// 	canActivate: [AuthGuard]
	// },
	// {
	// 	path: 'help',
	// 	component: HelpComponent,
	// 	canActivate: [AuthGuard]
	// },
	// {
	// 	path: 'geneology',
	// 	component: GeneologyComponent,
	// 	canActivate: [AuthGuard]
	// },
	{
		path: 'search',
		component: SearchComponent,
		canActivate: [AuthGuard]
	},
	// {
	// 	path: 'acknowledgements',
	// 	component: AcknowledgementsComponent,
	// 	canActivate: [AuthGuard]
	// },
	{
		path: 'terms',
		component: TermsComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'contact',
		component: ContactComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'resource/:resource_id',
		component: ResourceComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{path: '**', redirectTo: 'expo/resources', pathMatch: 'full'},
	// {
	//     path: '**',
	//     component: SplashComponent
	// },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {useHash: true})
	],
	exports: [
		RouterModule
	],
	declarations: []
})
export class RoutingModule {
}
