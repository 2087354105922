export class ResourceType {
	id: number;
	title: string;
	description: string;
	created: Date;
	modified: Date;
	resources: string[];

	constructor(data: any = {}) {
		this.id = data.id ? data.id : undefined;
		this.title = data.title ? data.title : undefined;
		this.description = data.description ? data.description : undefined;
		this.created = data.created ? data.created : undefined;
		this.modified = data.modified ? data.modified : undefined;
		this.resources = data.resources ? data.resources : undefined;
	}

	get formattedTitle() {
		return this.title.replace('[maps]', '');
	}
}
